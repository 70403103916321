<template>
  <div class="xieyi">
    <van-nav-bar
      title="用户协议"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="main">
      <p class="part">欢迎使用 大咖货源 提供的电子商务交易服务，为保障您的权益，请在使用大咖货源前，详细阅读本协议的所有内容，特别是加粗部分。当您注册大咖货源并勾选同意《大咖货源用户服务协议》时，您的行为表示您同意并签署了本电商服务协议，并同意遵守本协议中的约定。 该协议构成您与“大咖货源”达成的协议，具有法律效力。本协议内容包括协议正文、本协议下述条款明确援引的其他协议、大咖货源已经发布的或将来可能发布的各类规则。所有规则为本协议不可分割的组成部分，与协议正文具有同等法律效力。一旦相关内容发生变动，大咖货源将会通过站内信、电子邮件或网站公告等方式提示您。如果您不同意对本协议内容所做的修改，则应立即停止使用本服务；如果您继续使用本服务的，则视为您同意对本协议内容所做的修改。</p>

      <p>一、大咖货源向您提供电商平台化服务。</p>

      <p class="part">您登录大咖货源后，可选购入驻商家提供的商品或服务，与之达成交易意向并进而完成交易，您还可以参加大咖货源组织的活动，使用其它信息服务及技术服务。除大咖货源页面另有说明外，由供货商家承担相关售后责任。本协议项下除另有说明外，“大咖货源”特指大咖货源平台化服务提供方。</p>

      <p class="part">商家是指通过大咖货源陈列、售卖商品或服务，并最终和您完成交易的主体，是商品或服务的供应商和销售商。本协议项下的商家，除非另有说明，特指自营商家及第三方入驻商家（即自营商家以外的其他商家）的统称。</p>

      <p>二、大咖货源服务规范</p>

      <p>1、大咖货源所售商品和服务由商家向您提供，由其承担其商品和服务的质量保证责任。大咖货源会促使商家保障商品和服务质量，并尽可能准确、详尽地描述每一件商品。然而大咖货源不能逐一审查所有商品或服务的相关内容是准确、可靠和没有错误的，如您发现任何错误，请及时与大咖货源或商家联系。需要特别说明的是，由于用不同的网络浏览器或不同的计算机显示屏观看时网页显示的差异，大咖货源上展示的商品在图像和颜色方面可能跟真实的物品不尽一致。因此，所有显示的图片、视频和其他商品显示方法仅限于图示目的。</p>

      <p>2、大咖货源已对商家的信息、资质资格进行登记、核验，您在大咖货源所选购商品的描述均由商家提供，您应该对您对该等商品和服务所作出的判断负责，大咖货源不参与您与商家的交易，但将根据大咖货源的相关规则督促商家履行对您的售后保障服务。如您发现商家提供的商品或服务存在质量问题，请您与商家联系或通知大咖货源，大咖货源将进行核查并采取相关措施，切实保障您的合法权益。</p>

      <p>3、除非另有证明，大咖货源储存在其服务器上的数据是您使用大咖货源服务的唯一有效证据。</p>

      <p>4、在法律法规允许的最大限度内，大咖货源保留自行决定是否提供服务、注销用户帐号、清除或编辑内容或取消订单的权利。</p>

      <p>三、大咖货源使用规则</p>

      <p>1、您可浏览大咖货源中商家陈列的商品或服务信息，如您希望选购并支付订单的，您需先登录或注册大咖货源帐号，并根据页面提示提交选购的商品信息和个人信息，包括但不限于数量、规格、手机、姓名、身份证照片、送货地址等信息。 您在注册时承诺遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性等七条底线，不得在注册资料中出现违法和不良信息，且您保证其在注册和使用帐号时。不得有以下情形：</p>

      <p>（a）违反宪法或法律法规规定的；</p>

      <p>（b）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>

      <p>（c）损害国家荣誉和利益的，损害公共利益的；</p>

      <p>（d）煽动民族仇恨、民族歧视，破坏民族团结的；</p>

      <p>（e）破坏国家宗教政策，宣扬邪教和封建迷信的；</p>

      <p>（f）散布谣言，扰乱社会秩序，破坏社会稳定的；</p>

      <p>（g）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>

      <p>（h）侮辱或者诽谤他人，侵害他人合法权益的；</p>

      <p>（i）含有法律、行政法规禁止的其他内容的。</p>

      <p class="part">若您提供给大咖货源的帐号注册资料不准确，不真实，含有违法或不良信息的，大咖货源有权不予注册，并保留终止您使用大咖货源各项服务的权利。若您以虚假信息骗取帐号注册或帐号头像、个人简介等注册资料存在违法和不良信息的，大咖货源有权采取通知限期改正、暂停使用、注销登记等措施。对于冒用关联机构或社会名人注册帐号名称的，大咖货源有权注销该帐号，并向政府主管部门进行报告。 根据相关法律、法规规定以及考虑到大咖货源服务的重要性，您同意：</p>

      <p>（a）在注册大咖货源时或根据法律法规要求时提交个人有效身份信息进行实名认证；</p>

      <p>（b）提供及时、详尽及准确的帐户注册资料；</p>

      <p>（c）及时更新注册资料，符合及时、详尽准确的要求，对注册邮箱帐号时填写的身份证件信息不能更新。</p>

      <p class="part">大咖货源将按照相关法律法规规定，对您提交的注册资料进行核查认证。</p>

      <p class="part">您确认：在使用大咖货源服务过程中，您应当是具备完全民事权利能力和完全民事行为能力的自然人、法人或其他组织并对您提交信息的真实性承担一切责任。若您不具备前述主体资格，则您及您的监护人应承担因此而导致的一切后果，大咖货源有权向您的监护人追偿。</p>

      <p class="part">您知悉并理解，如不同账号绑定或关联同一手机号、同一支付账户、同一身份证信息、同一设备号或相同收货信息，所述不同账号将被视为关联账号。</p>

      <p class="part">您同意并保证：大咖货源有权记录您在选购商品或服务时，在线填写的所有信息并提供给商家、第三方服务提供者（包括但不限于物流公司、支付机构）或大咖货源的关联公司。您保证该等信息准确、合法，您承担因该等信息错误、非法等导致的后果。您授权大咖货源在本服务期间有权使用上述信息及将向有关第三方提供该信息。如您提供的信息过期、无效进而导致商家或大咖货源无法与您取得联系的，因此导致您在使用大咖货源服务中产生任何损失或增加费用的，应由您完全独自承担。</p>

      <p>3、您理解并同意：大咖货源实行先付款后发货的方式，您及时、足额、合法的支付选购商品或服务所需的款项是商家给您发货的前提。</p>

      <p>4、您支付交易款项过程中，可复查选购商品或服务的信息，包括单价、购买数量，付款方式，商品的运输方式和费用等。前述存储于大咖货源服务器的订单信息被认为是您的该次交易对应的发货、退货和争议事项的证据。您点击“提交订单”意味着您认可订单表格中包含的所有信息都是正确和完整的。由于众所周知的互联网技术因素等客观原因存在，大咖货源显示的信息可能会有一定的滞后性或差错，对此情形您知悉并理解。如因系统故障或大咖货源或商家过失导致显示信息明显不合理的（包括但不限于商品价格明显偏低），请勿进行下一步操作并立即通知大咖货源进行修改，如您明知显示信息明显不合理仍然提交订单的，将被视为恶意购物行为，大咖货源将有权按照本条第7款进行处理。</p>

      <p>5、您知悉并确认，您所选购的商品的所有权在您完成支付且商家完成打包时由商家转移给您。</p>

      <p>6、您所选购的商品或服务将被送至订单上注明的送货地址。该等物流服务由您所选购商品或服务的商家提供。无论何种原因该商品不能送达到送货地址，请您尽快跟页面公示的大咖货源客服或商家客服热线取得联系并解决。在您自行选择物流服务提供商的情况下，商品运输过程中的全部风险与责任，将由您自行与该物流服务提供商协调处理。</p>

      <p>7、您理解并保证，您在使用大咖货源服务的过程中遵守诚实信用原则，不扰乱大咖货源的正常交易秩序，如果大咖货源根据您的登录帐号下的交易记录及其他相关信息，发现您有以下任一情形的：</p>

      <p>（a）您此前通过大咖货源购买的商品多数并非用于个人消费或使用用途的；</p>

      <p>（b）您此前存在多次（2次及以上）恶意购物行为的，即从事相关购物行为，但积极主动终止购物目的或结果，或追求一般购物以外的目的或结果，对他人或大咖货源（可能）造成消极影响的行为。</p>

      <p>（c）大咖货源有合理理由怀疑您存在违反诚实信用原则的其他行为。</p>

      <p class="part">则，大咖货源有权自行或根据商家的要求拒绝您的购买需求，若您已下达订单的，大咖货源有权单方面取消订单不予发货或通知商家不予发货而无需承担任何责任；同时，大咖货源有权视情况冻结您的登录帐号，使之无法通过大咖货源下达订单、购买商品。您确认并同意，前述管理措施将同样适用于您的关联账号。</p>

      <p>8、您可将您与商家关于交易的争议提交大咖货源，您同意委托大咖货源单方判断与该争议相关的事实及应适用的规则，进而作出处理决定，该判断和决定将对您具有法律约束力。但您理解并同意，大咖货源并非司法机构，仅能以普通人的身份对证据进行鉴别，大咖货源对争议的调处完全是基于您的委托，大咖货源无法保证争议处理结果符合您的期望，也不对争议调处结论承担任何责任。如您因此遭受损失，您同意自行向责任方追偿。</p>

      <p>9、为使您能够及时、全面了解大咖货源提供的各类商品、服务及活动，您了解并同意，大咖货源可以通过您在注册、使用大咖货源过程中提供的联系方式多次、长期向您发送各类商业性短信息而无需另行获得您的同意。</p>

      <p>10、您了解并同意，根据国家相关的监管法律法规，您委托大咖货源接入的第三方支付机构向其合作银行发送购买外汇的请求并将相关支付信息及支付账户实名认证信息（包括姓名及身份证号码等）发送给大咖货源。因此您还需同意该第三方支付机构需要您确认的相关协议，并承担由此所产生的法律后果。</p>

      <p>11、您知悉并同意，大咖货源将按照页面公示的《大咖货源隐私政策》的约定收集、使用并保护您的相关信息。</p>

      <p>12、您知悉并确认，您因使用大咖货源而发生的所有应纳税赋以及其它方面的费用均由您自行支付。您保证遵守《海关法》和国家相关法律法规，在大咖货源购买的商品均为您个人合理自用，您愿意接受海关、检验检疫机构及其他监管部门的监管，并承担相应法律责任。</p>

      <p>13、跨境电子商务零售进口商品的单次交易限值与年度交易限值按照国家相关政策执行，如因超过上述限值而产生的所有后果（包括但不限于清关失败等）及法律责任均由您自行承担，如因此给大咖货源造成任何损失的，大咖货源有权向您追偿。</p>

      <p>四、其他约定</p>

      <p>1、责任范围和限制</p>

      <p>A 您了解大咖货源提供的平台作为您获取商品或服务信息、物色交易对象、就商品和/或服务的交易进行协商及开展交易的非物理场所，无法控制交易所涉及的商家提供的商品的质量、安全及商品信息的真实性或准确性，以及交易各方履行其在贸易协议中各项义务的实际能力。您应自行谨慎判断确定相关商品及/或信息的有效性，并自行承担因此产生的责任与损失。</p>

      <p>B 对发生下列情形之一所造成的不便或损害，您同意大咖货源免责，但大咖货源将尽合理努力尽快恢复正常运作并止损：</p>

      <p>a 在大咖货源已尽必要管理的情况下，定期检查或施工，更新软硬件而造成的常规或紧急服务中断，或突发性的软硬件设备与电子通信设备故障；</p>

      <p>b 网络服务提供商线路故障或其他超出大咖货源合理控制范围的第三方行为造成服务器遭受损害，无法正常运作；</p>

      <p>c 在紧急情况之下依照法律的规定而采取的措施；</p>

      <p>d 因您提供的身份信息不完整或不准确而导致的海关通关失败，或因您自身原因导致所购买的商品被海关扣留；</p>

      <p>e 您与其它任何第三方的纠纷。</p>

      <p>C 无论何种原因大咖货源对您的购买行为的赔偿金额将不会超过您受到影响的当次购买行为已经实际支付的费用的总额。</p>

      <p>2、所有权及知识产权：</p>

      <p>1）大咖货源上所有内容，包括但不限于文字、软件、声音、图片、录像、图表、网站架构、网站画面的安排、网页设计、在广告中的全部内容、商品以及其它信息均由大咖货源或其他权利人依法拥有其知识产权，包括但不限于商标权、专利权、著作权、商业秘密等。非经大咖货源或其他权利人书面同意，您不得擅自使用、修改、全部或部分复制、公开传播、改变、散布、发行或公开发表、转载、引用、链接、抓取或以其他方式使用本平台程序或内容。如有违反，您同意承担由此给大咖货源或其他权利人造成的一切损失。</p>

      <p>2）大咖货源不保证平台上由其他权利人提供的所有内容没有侵犯任何第三方的合法权益，如您认为前述内容侵犯您的合法权益，请及时与大咖货源客服联系，大咖货源将在法律规定范围内协助您解决问题。</p>

      <p>3、您了解并同意，大咖货源根据自身的判断，认为您的行为涉嫌违反本协议的条款，则大咖货源有权暂停或停止向您提供服务，且无须为此向您或任何第三方承担责任。</p>

      <p>4、本协议适用中华人民共和国的法律。 当本协议的任何内容与中华人民共和国法律相抵触时，应当以法律规定为准，同时相关条款将按法律规定进行修改或重新解释，而本协议其他部分的法律效力不变。</p>

      <p>5、如在使用大咖货源过程中，就商品产生的任何纠纷，应由您和相应销售方进行协商处理，就平台化服务产生的任何纠纷，应由您和大咖货源进行协商处理。若协商不成，应将上述纠纷提交中国国际经济贸易仲裁委员会由三名仲裁员根据其仲裁规则进行仲裁。</p>

      <p>6、本协议自发布之日起实施，并构成您和大咖货源之间的共识。</p>

      <p>7、如果您对本协议内容有任何疑问，可发送邮件至midbestvip@163.com联系大咖货源客服咨询。</p>

    </div>
  </div>
</template>
<script>
export default {
  name: "xieYi",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      shareid: 0,
      merchid: 0,
    }
  },
  mounted() {
    this.islogin = localStorage.getItem("DK_ISLOGIN") ? true : false;
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "用户协议");
  },
  methods: {
    // 返回上一页
    onClickLeft() {
      this.$router.back();
    },
  },
}
</script>
<style lang="less">
.xieyi {
  overflow: hidden;
  width: 100%;
  min-height: 100%;
  position: relative;
  z-index: 2;
  background-color: #fff;
  .van-nav-bar .van-icon {
    color: #333;
  }
  .main {
    padding: 10px;
    overflow: hidden;
    font-size: 14px;
    color: #333;
    line-height: 20px;
    text-align: left;
    .part {
      text-indent: 30px;
    }
  }
}
</style>